<template>
  <b-modal
    id="sets-rates-modal"
    ref="sets-rates-modal"
    hide-footer
    header-class="p-2"
  >

    <template #modal-header="{ close }">
      <div>
        <div><small>กรุณาเลือกราคา</small></div>
        <h5 class="mb-0 text-primary">{{marketTitle}} {{roundDate}}</h5>
      </div>
      <button class="btn btn-sm btn-outline-secondary border-0" @click="close()"><i class="fas fa-times"></i></button>
    </template>

    <div class="row no-gutters">
      <div v-for="rate in rates" :key="rate._id" class="custom-col col-md-6">
        <div class="round-rate border-info rounded p-2" @click="goPlay(rate._id)">
          <h5 class="text-success mb-1">{{ rate.rateTitle }}</h5>
          <h6 class="mb-1 text-secondary">ราคา {{rate.price | amountFormat}} บาท/ชุด</h6>
          <h6 v-if="rate.discount > 0" class="mb-1 text-secondary">ส่วนลด {{rate.discount | amountFormat}} บาท/ชุด</h6>
          <PrizeTable :market="market" :rate="rate" />
          <div class="text-center pt-2">
            <button class="btn btn-info">เลือกชุด {{rate.price}}</button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from '@/helpers/moment'
import PrizeTable from './PrizeTable'

export default {
  name: 'SetsRatesModal',
  components: {
    PrizeTable
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    market: {
      type: Object,
      default: {}
    },
    round: {
      type: Object,
      default: {
        _id: null,
        roundDate: {
          close: null,
          date: null,
          open: null
        }
      }
    },
    rates: {
      type: Array,
      default: []
    }
  },
  computed: {
    marketTitle() {
      return this.market?.marketTitle
    },
    roundDate() {
      return moment(this.round.roundDate.date).format("DD/MM/YYYY")
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    showModal() {
      this.$refs['sets-rates-modal'].show()
    },
    hideModal() {
      this.$refs['sets-rates-modal'].hide()
    },
    goPlay(rateId) {
      this.hideModal()

      if(this.$route.params.roundId === this.round._id && this.$route.params.rateId === rateId)
        return

      this.$router.push({name: 'PlayLottosetRoundId', params: { roundId: this.round._id, rateId: rateId }})
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#sets-rates-modal {
  .modal-dialog {
    max-width: 800px;

    .modal-body {
      padding: 10px;

      .custom-col {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;

        .round-rate {
          cursor: pointer;
        }

        .round-rate:hover {
          background-color: #d1ecf1;
        }
      }
    }
  }
}
</style>
