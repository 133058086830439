<template>
  <div class="card round-box p-2 m-1 text-light" :class="[cssClass.bg, cssClass.text]" @click="toggle">
    <span v-if="marketIcon" class="pt-1 mr-2">
      <img :src="marketIcon" style="border-radius: 3px;">
    </span>
    <div class="p-0">
      <h5 class="m-0">{{marketTitle}}</h5>
      <span class="font-weight-bold">{{roundDate}}</span>

      <div v-if="isWaitToOpen" class="d-flex justify-content-between">
        <span class="font-weight-light small">เวลาเปิด</span>
        <span class="font-weight-light small">{{openDate}}</span>
      </div>

      <div v-else-if="!isClosed" class="d-flex justify-content-between">
        <span class="font-weight-light small">เวลาปิด</span>
        <span class="font-weight-light small">{{closeDate}}</span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="font-weight-light">สถานะ</span>
        <span class="font-weight-light">
          <RoundStatus :data="data" @cssStyle="setStyle" @changeStatus="changeStatus" />
        </span>
      </div>
    </div>

    <SetsRatesModal :market="market" :round="round" :rates="rates" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import RoundStatus from '@/views/PlayLotto/components/RoundStatus'
import Swal from 'sweetalert2'
import SetsRatesModal from './SetsRatesModal'

export default {
  name: 'SetsRound',
  props: ['data'],
  components: {
    RoundStatus,
    SetsRatesModal
  },
  data() {
    return {
      isShowModal: false,
      cssClass: {
        bg: null,
        text: null
      }
    }
  },
  computed: {
    roundDate() {
      return moment(this.data.roundDate.date).format("DD-MM-YYYY")
    },
    openDate() {
      return moment(this.data.roundDate.open).format("DD/MM/YY HH:mm:ss")
    },
    closeDate() {
      return moment(this.data.roundDate.close).format("DD/MM/YY HH:mm:ss")
    },
    marketTitle() {
      return this.data.market?.marketTitle
    },
    marketIcon() {
      return this.data.market?.imageIcon
    },
    isWaitToOpen() {
      return moment().isBefore(this.data.roundDate.open)
    },
    isOpen() {
      return moment().isBetween(this.data.roundDate.open, this.data.roundDate.close)
    },
    isClosed() {
      return moment().isAfter(this.data.roundDate.close)
    },
    marketType() {
      return this.data?.market?.marketType || 'single'
    },
    market() {
      return this.data?.market ?? {}
    },
    rates() {
      return this.data?.rates ?? []
    },
    round() {
      return {
        _id: this.data._id,
        roundDate: this.data.roundDate
      }
    }
  },
  methods: {
    setStyle(css) {
      this.cssClass = {
        ...this.cssClass,
        ...css
      }
    },
    toggle() {
      if(this.isOpen) {
        if(this.marketType === 'lottoset') {

          if(!this.rates.length) {

          }else
          if(this.rates.length === 1) {
            this.goPlay(this.rates[0]._id)
          }else
          if(this.rates.length > 1){
            this.isShowModal = true
          }
        }else{
          this.$router.push({name: 'PlayRoundId', params: { roundId: this.data._id }})
        }
      }else
      if(this.isClosed) {
        this.$router.push({name: 'Results'})
      }else
      if(this.isWaitToOpen) {
        Swal.fire({
          text: 'ยังไม่เปิดรับแทง',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }
    },
    changeStatus(needReload) {
      console.log('changeStatus', needReload)
      if(needReload) {
        this.$emit('reload')
      }
    },
    goPlay(rateId) {
      this.$router.push({name: 'PlayLottosetRoundId', params: { roundId: this.data._id, rateId: rateId }})
    },
    modalClosed() {
      this.isShowModal = false
    }
  }
}
</script>
<style lang="scss" scoped>
.round-box {
  flex-direction: row;
  cursor: pointer;
  height: 100%;

  span {
    img {
      width: 60px;
    }
  }

  div {
    width: 100%;
  }
}
</style>
